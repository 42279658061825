.attention-tag {
  display: inline-flex;
  align-items: center;
  padding: 4px 12px;
  border-radius: 20px;
  background: linear-gradient(45deg, #ff69b4, #ff8c00, #b700ff);
  color: #fff;
  font-weight: bold;
  position: relative;
  overflow: hidden;
  height: fit-content;
}

.star {
  position: absolute;
  top: 50%;
  width: 16px;
  height: 16px;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.8) 50%, transparent 70%);
  border-radius: 50%;
  animation: twinkling 1.5s infinite;
}

.left-star {
  left: -8px;
  animation-delay: 0.2s;
}

.right-star {
  right: -8px;
  animation-delay: 0s;
}

@keyframes twinkling {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
  }
}
