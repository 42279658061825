@import '~antd/dist/antd.less';

body {
  font-family: 'Atkinson Hyperlegible', sans-serif;
}

.App {
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

// menu
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #0f0f11;
}
.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a,
.ant-menu-dark .ant-menu-item > span > a {
  color: rgba(255, 255, 255, 0.9);
}

// page headers
.PageHeader {
  box-shadow: 4px 4px 8px rgba(26, 26, 53, 0.1);
  background-color: #ffffff;
  position: fixed;
  top: 0;
  width: calc(100vw - 0px);
  z-index: 200;
}

.ant-page-header-heading-extra {
  margin: 4px 200px 4px 0px;
  white-space: nowrap;
}

@media only screen and (max-width: 1000px) {
  .ant-page-header-heading {
    display: block;
    justify-content: space-between;
  }
  .main-body {
    padding: 100px 20px 20px !important;
  }
  .ant-row-no-wrap {
    flex-wrap: wrap;
  }
  .contact-phone-number {
    width: 100% !important;
  }
  .date-range-picker {
    width: 215px !important;
  }
  .customer-picker {
    width: 195px !important;
  }
  .sales-toggle div {
    flex-wrap: wrap;
    text-align: left;
    gap: 0px !important;
  }
  .sales-toggle div div div div:nth-child(4) {
    margin-left: -15px;
  }
  .sales-toggle div div div:nth-child(3) {
    margin-left: 72px;
  }
  .payment-due-date div div:nth-child(3) {
    margin-left: 25px !important;
  }
  .sales-toggle .ant-picker-input input {
    width: auto;
  }
  .sales-toggle .ant-switch {
    margin-left: 45px;
  }
  .item-table {
    min-width: 594px;
  }
  .receive-return-timeline li div div div {
    flex-wrap: wrap;
  }
  .invoice-pdf-preview {
    display: none;
  }
  .send-invoice-email-fields {
    flex: none;
    max-width: 100%;
  }
  .make-the-sale-header {
    flex-wrap: wrap;
  }
  .make-the-sale-invoices {
    margin-top: 140px !important;
  }
  .PageHeader div span.ant-page-header-heading-extra {
    margin-right: 245px;
  }
  .PageHeader div.ant-page-header-heading {
    flex-wrap: wrap;
  }
  .available-inventory-table div.ant-col.ant-col-6:nth-child(2) {
    display: none;
  }
  .available-inventory-table div.ant-col.ant-col-18 {
    max-width: 100%;
  }
  .available-inventory-date-picker {
    flex-wrap: wrap;
  }
  .route-elements-row {
    flex-wrap: wrap;
  }
  .routing-map {
    width: 100vw !important;
  }
  .routing-stops {
    width: 100vw !important;
    height: 100% !important;
  }
  .routing-routes {
    width: 100vw !important;
  }
  .route-header {
    margin: 0px;
    height: 55px;
    padding-bottom: 140px;
  }
  .route-assignment-list {
    min-width: 396px !important;
  }
  .email-invoice-preview {
    display: none;
  }
  .customize-invoice-email-fields {
    width: 100% !important;
  }
  .admin-inventory-list {
    margin-top: 75px !important;
  }
  .list-margin-top {
    margin-top: 85px !important;
  }
  .add-inventory {
    width: 100% !important;
  }
  .item-drawer div.ant-drawer-content-wrapper {
    width: 85% !important;
  }
}

@media only screen and (max-width: 1588px) {
  .route-elements-row {
    flex-wrap: wrap;
  }
}

div.ant-layout-sider-trigger {
  background-color: #0f0f11;
}

.ant-tooltip-inner {
  background-color: #0f0f11;
}

.route-assignment-list {
  margin-top: 15px;
}

.route-user-list {
  min-width: 396px;
}

.route-header {
  margin: 0px 0px -15px 0px;
  height: 55px;
}

.PageHeader div span.ant-page-header-heading-extra {
  margin-right: 245px;
}

.routing-stops {
  min-width: 310px;
  width: 20vw;
}

.routing-stops div.ant-card-body {
  padding: 0px !important;
}

.routing-routes {
  min-width: 396px;
  width: 25vw;
}

.routing-routes div.ant-card-body {
  padding: 0px !important;
}

.routing-map {
  min-width: 396px;
  width: 55vw;
}

.routing-map div.ant-card-body {
  padding: 0px !important;
}

.route-elements-row {
  width: 100%;
  gap: 0px !important;
}

.make-the-sale-invoices {
  margin-top: 68px;
  height: 100%;
}

.customer-picker {
  max-width: 416px;
}

.contact-phone-number {
  width: 200px;
}

.date-range-picker {
  width: 300px;
}

.main-body {
  padding: 20px 20px;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected.CompanyHeader {
  background-color: transparent;
}

.tableContextMenu {
  /*animation-name: fadeIn;*/
  animation-duration: 0.4s;
  background-clip: padding-box;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  left: 0px;
  list-style-type: none;
  margin: 0px;
  outline: none;
  padding: 0px;
  position: absolute;
  text-align: left;
  top: 0px;
  overflow: hidden;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 100;
}
.tableContextMenu li:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
.InvNotesCell {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.NewInvoiceCustomerForm {
  margin-bottom: 8px;
}

.FormItemNoValidatonMargin .ant-form-item-explain.ant-form-item-explain-error {
  min-height: 0;
}
.ant-timeline-item-head {
  margin-top: 3px;
}

.ant-timeline-item-tail {
  margin-top: 3px;
}

.row-dragging {
  background: #fafafa;
  border: 0;
  border-radius: 4px;
  box-shadow: 4px 4px 8px rgba(26, 26, 53, 0.1);
}

.row-dragging td {
  padding: 8px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}

.ais-RefinementList-checkbox {
}
.ais-RefinementList-item::marker {
  color: transparent;
}

.ais-RefinementList-item {
  margin: 8px 0;
}

.ais-RefinementList-list {
  padding-left: 8px;
}

.ais-RefinementList-labelText {
  margin-left: 8px;
  font-size: 15px;
  font-family: 'Atkinson Hyperlegible', sans-serif;
}
.ais-Pagination-list {
  justify-content: flex-end;
  display: flex;
  margin-block-start: 4px;
  margin-bottom: 4px;
  margin-top: 12px;
}

.ais-Pagination-item,
.ais-Pagination-link {
  align-items: center;
  display: flex;
  justify-content: center;
}

.ais-Pagination-item {
  height: 24px;
  width: 24px;
}

.ais-Pagination-item {
  background-color: rgba(65, 66, 71, 0.08);
  color: #414247;
  border-radius: 8px;
}

.ais-Pagination-item--selected {
  background-color: #6237e9;
  font-weight: bold;
}

.ais-Pagination-item--firstPage,
.ais-Pagination-item--previousPage,
.ais-Pagination-item--nextPage,
.ais-Pagination-item--lastPage {
  background: none;
}

.ais-Pagination-item--disabled {
  opacity: 0.33;
}

.ais-Pagination-item--selected a {
  color: #fff;
}

.ais-Pagination-item.ais-Pagination-item--page {
  margin-right: 4px;
}

.ais-Pagination-item.ais-Pagination-item--previousPage {
  margin-right: 1rem;
}

.ais-Pagination-item.ais-Pagination-item--nextPage {
  margin-left: calc(1rem - 4px);
}

.ais-Pagination-link {
  height: 100%;
  width: 100%;
}

// quick report
.events {
  margin: 0;
  padding: 0;
  list-style: none;
}
.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.notes-month {
  font-size: 28px;
  text-align: center;
}
.notes-month section {
  font-size: 28px;
}

.qr-selected-invoice {
  cursor: pointer;
  font-weight: bold;
  background: rgba(98, 55, 233, 0.06);
  border-radius: 8px;
  padding: 8px;
}

.qr-invoice {
  cursor: pointer;
  font-weight: normal;
  background: transparent;
  padding: 8px;
}

.qr-selected-inventory {
  cursor: pointer;
  font-weight: bold;
  background: rgba(98, 55, 233, 0.06);
  border-radius: 8px;
  padding: 8px;
}

.qr-inventory {
  cursor: pointer;
  font-weight: normal;
  background: transparent;
  padding: 8px;
}

.qr-calendar-cell-ready {
  background: rgba(59, 178, 124, 1);
  border-radius: 4px;
  padding: 0 4px;
  margin-bottom: 4px;
}

.qr-calendar-cell-hasIssue {
  background: rgba(232, 88, 88, 1);
  border-radius: 4px;
  padding: 0 4px;
  margin-bottom: 4px;
}
.qr-calendar-cell-in-progress {
  //background: rgba(88, 151, 234,.1);
  border-radius: 4px;
  padding: 0 4px;
  margin-bottom: 4px;
}
.ant-radio-group.ant-radio-group-outline.ant-picker-calendar-mode-switch {
  display: none;
}

.ant-list-item-action {
  margin-left: 8px;
}

.item-locate-table-header {
  background: #2f54eb;
  font-weight: bold;
}

.ant-table-row-expand-icon {
  margin-left: 7px;
}

.ant-input-number-handler-wrap {
  display: none;
}

.UnassignedRouteRow {
  //cursor: pointer;
}

.Marker-Stop-Number {
  margin-bottom: 9px;
  font-weight: bold;
}

.ant-collapse-item.Stop-Header {
  margin: -8px;
  background: transparent;
}

.SelectedStopRow {
  color: #1d39c4;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes fadeInUp {
  from {
    transform: translate3d(0, 20px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes fadeInUp {
  from {
    transform: translate3d(0, 20px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
.animated {
  animation-duration: 200ms;
  animation-fill-mode: both;
  -webkit-animation-duration: 200ms;
  -webkit-animation-fill-mode: both;
}

.animatedFadeInUp {
  opacity: 0;
}
.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}

.PhoneInputInput {
  border: 1px solid #fff;
  max-width: 175px;
  padding: 4px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}

.PhoneInputInput:hover {
  border-color: #8962f5;
}

.PhoneInputInput:focus {
  border-color: #8962f5;
  box-shadow: 0 0 0 2px rgb(97 56 233 / 20%);
}

@primary-color: #6138E9;@black: #0f0f11;@text-color: #0f0f11;@text-color-secondary: #4a4a4d;@page-header-padding-vertical: @padding-xs;@border-radius-base: 4px;@input-bg: #FAF9FB;